<template>
<section id="contact" class="contact section-bg">
  <div class="container">
    <div class="section-title">
          <h2>Login</h2>
        </div>

        <div class="row mt-5 justify-content-center"  >
          <div class="col-lg-10">

      <Form @submit="handleLogin" :validation-schema="schema">
                <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
        <br>
        <div class="form-group">
          <label for="username">Username</label>
          <Field name="username" type="text" class="form-control" />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <Field name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>
<br>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
   
            <span>Login</span>
          </button>
        </div>


      </Form>
    </div>
    </div>
  </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useToast } from "vue-toastification";
import { isAuthenticated } from '../services/auth.check';

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      isAuthenticated: isAuthenticated(),
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },

  setup() {
      const toast = useToast();
      return { toast }
    },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$store.commit("auth/setLoginStatus", true); 
          this.$router.push("/");
          this.toast.success(" Logged in!");
        },
        (error) => {
          this.loading = false;
          this.message = 'Invalid username or password'
          console.log(error)

        }
      );
    },
  },
};
</script>
