<template>
  <header id="header" class="fixed-top d-flex align-items-center">
    <div  v-if="!isMobile()"  class="header-social-links d-flex flex-row-reverse">
     <nav id="navbar" class="navbar ">     
      <ul>
          <li>
            <div v-if="!isLoggedIn">
              <a class="login">
                <router-link to="/login">
                  <i class="fas fa-user-lock"></i>
                </router-link>
              </a>
            </div>
          </li>
          <li>
            <div v-if="isLoggedIn">
              <b class="login">
                <router-link to="/profile">
                  <i class="fas fa-user-tie"></i>&ensp;{{ username }}
                </router-link>
              </b>
            </div>
          </li>
        </ul>
        </nav>
     </div>  
    <div  v-if="!isMobile()" class="container d-flex align-items-center">
      <div class="logo me-auto">
      </div>

      <nav id="navbar" class="navbar order-last order-lg-0">     
        <ul>
        <li><a class="nav-link scrollto active"><router-link to="/#hero">Home</router-link></a></li>
        <li><a class="nav-link scrollto active"><router-link to="/#news">News</router-link></a></li>
        <li><a class="nav-link scrollto active"><router-link to="/#about">About</router-link></a></li>
        <li><a class="nav-link scrollto active"><router-link to="/#contact">Contact</router-link></a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <div class="header-social-links d-flex align-items-center">
        <a href="https://www.facebook.com/groups/147448095267101/" class="facebook" ><i class="fab fa-facebook"/></a>
        <a href="#" class="instagram"><i class="fab fa-instagram"></i></a>
      </div>
      </div>
    <div v-if="isMobile()" class="container">
      <div class="row">
    <div class="col d-flex flex-row"> 
     <div class="mobilenav">
          <nav role="navigation">
            <div id="menuToggle">
              <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                 <ul id="menu">
                  <li><a class="nav-link scrollto active" ><router-link to="/#hero">Home</router-link></a></li>
                  <li><a class="nav-link scrollto active"><router-link to="/#news">News</router-link></a></li>
                  <li><a class="nav-link scrollto active"><router-link to="/#about">About</router-link></a></li>
                  <li><a class="nav-link scrollto active"><router-link to="/#contact">Contact</router-link></a></li>
             </ul>
           </div>
          </nav>
          </div>
        </div>
        <div class="col d-flex flex-row-reverse">
        <nav id="navbar" class="navbar ">     
        <ul>
         <li><div v-if="!isAuthenticated"><a class="login"><router-link to="/login" ><i class="fas fa-user-lock"></i></router-link></a></div></li>
        <li><div v-if="isAuthenticated"><b class="login"><router-link to="/profile"><i class="fas fa-user-tie"></i>&ensp;<div v-text="currentUser.sub"></div></router-link></b></div></li>
        </ul>
   <!--     <i class="bi bi-list mobile-nav-toggle"></i> -->
      </nav>
    </div>
       </div>
       </div>
  </header>
</template>

<script>
import { isAuthenticated } from '../../services/auth.check';

export default {
  data() {
    return {
      isAuthenticated: isAuthenticated(),
    }
  },
  methods: {
 isMobile() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     return true
   } else {
     return false
   }
 },
 checkAuthentication() {
      // Check authentication status
      this.isAuthenticated = isAuthenticated();
      console.log("this.isAuthenticated: " + this.isAuthenticated);

    }
},
async created() {
    this.isLoggedIn = await isAuthenticated();
    this.authReady = true; // Set authReady to true once authentication status is fetched
  },
 
};
</script>