<template>
  <section id="page" class="news section-bg" >
   <div class="container text-center text-md-left" style="margin-top: -50px; margin-bottom: 50px;"><h2>{{ getCategoryName }}</h2></div>
<div>
<vue-final-modal  v-model="arrangeModal" classes="modal-container">
 <div class="modal-content">
   <div class="modal-header">
     <h5 class="modal-title">Sort order</h5>
   </div>
   <div class="modal-body" ref="sortableList">
     <div v-for="(tab) in orderedTabs" :key="tab.id">
       <ul class="list-group">
         <li class="list-group-item"><i class="fa fa-bars" aria-hidden="true"/> {{ tab.title }}</li>
       </ul>
     <div></div>
     <!-- Other content of each item -->
   </div>
   </div>
   <div class="modal-footer">
     <button type="button" @click="arrangeModal = false" class="btn btn-primary">Done</button>
   </div>
 </div>

</vue-final-modal>
<vue-final-modal v-model="deleteModal" classes="modal-container" >
   <div class="modal-content">
   <div class="modal-header">
     <h5 class="modal-title">Do you really want to delete {{ deleteTitle }}?</h5> 
   </div>
   <div class="modal-body">

   </div>
   <div class="modal-footer">
     <button type="button" @click="deleteItem()" class="btn btn-primary">Yes</button>
     <button type="button" @click="deleteModal = false" class="btn btn-secondary" data-dismiss="modal">No</button>
   </div>
 </div>
 </vue-final-modal>
 <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
   <button class="modal__close" @click="showModal = false">
     <mdi-close></mdi-close>
   </button>
   <span class="modal__title"><i class="fas fa-newspaper"></i></span>
   <div class="modal__content">
<div class="input-group mb-3">
<div class="input-group-prepend">
 <span class="input-group-text" id="inputGroup-sizing-default">Titel</span>
</div>
<input type="text" class="form-control" v-model="title" aria-label="Default" aria-describedby="inputGroup-sizing-default">
</div>
     <ck-editor v-model="editorData" ></ck-editor>

   </div>
  <div  class="modal__action">
     <div v-if="this.showSubmit == true" ><button type="button" class="btn btn-primary btn-sm" @click="submit">Confirm</button></div>
     <div v-if="this.showUpdate == true" ><button type="button" class="btn btn-primary btn-sm"  @click="editsItemCommit">Update</button></div>
     &emsp;
     <button type="button" class="btn btn-primary btn-sm" @click="showModal = false">Cancel</button>
   </div>
 </vue-final-modal>
   <div v-if="isAuthenticated" class="changeButton d-flex flex-row-reverse">
     <a class="changeButtonCreate" @click="arrangeModal = true" ><i class="fa fa-bars" aria-hidden="true" /></a>
     <a class="changeButtonCreate" @click="createNewItem" ><i class="fas fa-plus-circle" /></a>
     
   </div>
</div>
<!-- Sections -->
<div class="container">
<carousel :items-to-show="numberOfItemsToShow" :breakpoints="breakpoints">
    <slide v-for="(item) in orderedTabs" :key="item.orderid">
      <div v-if="isAuthenticated" class="newsButton d-flex justify-content-left mb-2">
    <a class="newsButtonEdit mr-2" @click="editsItem(item.data, item.id, item.title)">
      <i class="fas fa-edit" />
    </a>
    <a class="newsButtonEdit" @click="deletePop(item.id, item.title)">
      <i class="fas fa-trash" />
    </a>
  </div>
  <div class="carousel__item"  @click="sections(item.categoryId, item.id)" >
      <h3 class="title"><div v-html="item.title"></div></h3>
  </div>
    </slide>
    <template #addons>
      <navigation v-if="showNavigation"/>
      <pagination />
    </template>
  </carousel>
</div>
     <div>
       <router-view :itemData="selectedItemData">
         <div class="container">
           <div  class="card" style="margin-top: -20px;"> 
           <div class="card-text" v-html="selectedItemData" style="margin-left: 20px;margin-right: 20px;">
           </div>
           </div>
         </div>
       </router-view>
     </div>
 </section>
</template>


<script>
import gql from "graphql-tag";
import { VueFinalModal } from 'vue-final-modal'
import ckEditor from './subcomponents/ckeditor'
import {useToast } from "vue-toastification";
import {useRoute} from 'vue-router'
import { isAuthenticated } from '../services/auth.check';
import {apolloContext} from '../apollo.provider'
import Sortable from 'sortablejs';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

const UPDATE_SECTION_ITEM = gql`
mutation updateSections($id: Int, $data: String, $title: String)
{
updateSections(
 id: $id
 data: $data
 title: $title
) 
 {
 id
 }
}`;


const UPDATE_SECTION_ORDER = gql`
mutation updateSectionsOrder($id: Int, $orderid: Int)
{
 updateSectionsOrder(
 id: $id
 orderid: $orderid
) 
 {
 id
 }
}`; 
const CREATE_SECTION_ITEM = gql`
mutation createSections($categoryId: Int, $data: String, $title: String) {
createSections(categoryId: $categoryId, data: $data, title: $title){id}
}`;

const DELETE_SECTION = gql`
mutation ($id: Int!) {
 deleteSections(id: $id)
}
`;

const ALL_CATEGORIES = gql`
query getCategoriesById($id:Int) {
getCategoriesById(id:$id) {
 id
 created
 categoryname
 description
 sections {
   id
   created
   data
   title
   orderid
 }
 
}
 }
`;

const SECTION_BY_ID = gql`
query getSectionsById($id: Int) {
 getSectionsById(id: $id) {
   id
   created
   data
   title
   orderid
 }
}
`;


const CATEGORY_NAME = gql`
query getCurrCategoryName($id: Int) {
  getCurrCategoryName(id: $id) {
  categoryname
 }
}
`;

const ALL_SECTION_TITLES = gql`
query getRelCategories($id:Int) {
getRelCategories(id:$id) {
 categoryname
 sections {
   id
   title
   orderid
 }
 
}
 }
`;

export default {
 beforeRouteEnter(to, from, next) {
   try {
     next(vm => {
     console.log('vm:', vm);
     vm.sections(to.params.categoryId, to.params.sectionsId);
   });
     
   } catch (error) {
     console.error('Error fetching section by ID:' + error);
   }

 },
   data() {
 return {
   isAuthenticated: isAuthenticated(),
   showModal: false,
   editorData: '',
   selectedItemData: null,
   editorIndex: '',
   deleteModal: false,
   deleteTitle:'',
   deleteIndex:'',
   categoryName: '',
   arrangeModal: false,
   showSubmit: true,
   showUpdate: false,
   isEditable: true,
   editSave: 'Edit',
   whiskeyTitle: '',
   title: '',
   CKEditor: {
    name: 'ckeditor',
   }
 };
},  
apollo: {
 getCategoriesById: {
   query: ALL_CATEGORIES,
   variables() {
     return {
       id: this.categoryId
     };
   },
   skip() {
     return !this.categoryId;
   }
 }, 
 getRelCategories: {
   query: ALL_SECTION_TITLES,
   variables() {
     return {
       id: this.categoryId
     };
   },
   skip() {
     return !this.categoryId;
   }
},
getCurrCategoryName: {
   query: CATEGORY_NAME,
   variables() {
     return {
       id: this.categoryId
     };
   },
   skip() {
     return !this.categoryId;
   }
},
  
},

methods: {
 sections(categoryId,sectionsId) {
  try {
   this.$apollo.query({
    query: SECTION_BY_ID,
 variables: {
   id: sectionsId
 }
})
.then((response) => {
 this.selectedItemData = response.data.getSectionsById.data
 console.log('Response:', response.data);
 this.$router.push({
   name: 'SectionsPage',
   params: { categoryId: categoryId, sectionsId: sectionsId }
 });
}).catch((error) => {
 console.error('Error fetching section by ID:' + error);
});
  }catch (error) {
 console.error('Error fetching section by ID:' + error);
}

},


   createNewItem()
   {
     this.showSubmit = true
     this.showModal = true
     this.editorData = ""
     this.title = ""

   },
   editsItem(event, index, title){

    try {
   this.$apollo.query({
    query: SECTION_BY_ID,
 variables: {
   id: index
 }
})
.then((response) => {
  this.editorData = response.data.getSectionsById.data
  console.log(event)
   this.showSubmit = false
   this.showUpdate = true
   this.showModal = true
   this.editorIndex = index
   this.title = title
    console.log('Response:', response.data);

}).catch((error) => {
 console.error('Error fetching section by ID:' + error);
});
} catch (error) {
 console.error('Error fetching section by ID:' + error);
}
    
   },



   saveArrangeChanges()
   {
     this.orderedTabs.forEach(tab => {
     console.log(tab.id)
     apolloContext.requiresAuth = true; 
     this.$apollo.mutate({
     mutation: UPDATE_SECTION_ORDER,
     variables: {
     id: tab.id,
     orderId: tab.orderid
}
});

});

   },
async onSortEnd(event) {
 const { newIndex, oldIndex } = event;
 const movedTab = this.orderedTabs.splice(oldIndex, 1)[0];
 this.orderedTabs.splice(newIndex, 0, movedTab);
 apolloContext.requiresAuth = true; 
 try {
   await this.$apollo.mutate({
     mutation: UPDATE_SECTION_ORDER,
     variables: {
       id: movedTab.id,
       orderid: newIndex // Assuming this is the field representing the order in your backend
     }
   });
   await this.$apollo.queries.getCategoriesById.refetch();
 } catch (error) {
   // Handle error if mutation fails
   console.error('Error updating order:', error);
 }
 console.log(this.orderedTabs)

 },
   deletePop(index, title){
   apolloContext.requiresAuth = true; 
   console.log("Ready to delete " + title)
   this.deleteTitle = title
   this.deleteModal = true
   this.deleteIndex = index
   },
   deleteItem(){
   apolloContext.requiresAuth = true; 
   console.log("Deleted: " + this.deleteTitle + " by user")
   this.$apollo.mutate({
     mutation: DELETE_SECTION,
     variables: {
       id: this.deleteIndex,
     }
   });
   this.$apollo.queries.getCategoriesById.refetch()
   
   },

   editsItemCommit(){
   apolloContext.requiresAuth = true; 
   if(this.title != "")
   {
   this.$apollo.mutate({
     mutation: UPDATE_SECTION_ITEM,
     variables: {
       id: this.editorIndex,
       data: this.editorData,
       title: this.title
     }
   
 
 // eslint-disable-next-line no-unused-vars
 }).then(data => {    
   this.loading = false
   this.showModal = false
   this.showUpdate = false
   this.showSubmit =  true
   this.editorData = 
   this.toast.success("Your post was successfully updated");
 })
 .catch(error => {
 console.log('errors', error.err)
   this.loading = false
 this.toast.error("Something happened, any large images?");
});
   this.$apollo.queries.getCategoriesById.refetch()
     }else{
     this.toast.error("You need a title");
   }
},
   async submit(e) {
   apolloContext.requiresAuth = true; 
   if(this.title != "")
   {
   const data = this.editorData;
   const title = this.title;
   
   //$id: Int, $data: String, $title: 
   var sectionId = this.sectionId

   
   e.preventDefault();
    await this.$apollo.mutate({
     mutation: CREATE_SECTION_ITEM,
     variables: {
       sectionId,
     data,
     title
     }
 // eslint-disable-next-line no-unused-vars
 }).then(data => {
   this.loading = false
   this.showModal = false
   this.showUpdate = false
   this.showSubmit =  true
   this.editorData = 
   this.toast.success("Your post was successfully updated");
 })
 .catch(error => {
 console.log('errors', error.err)
 this.loading = false
 this.toast.error("Something happened");
});
   this.$apollo.queries.getCategoriesById.refetch()
     }else{
     this.toast.error("You need a title");
   }
   }     
 
},
computed: {

 categoryId() {
   console.log("this.$route: " + this.$route.params.categoryId)
   return this.$route.params.categoryId;
 },
 getCategoryName() {
    if (this.getCurrCategoryName && this.getCurrCategoryName.length > 0) {
      const category = this.getCurrCategoryName[0];
      return category.categoryname;
    }
    return ''; // or any default value
  },
 orderedTabs: function () {
console.log('getRelCategories:', this.getRelCategories);
const sectionsMap = new Map();

if (this.getRelCategories && this.getRelCategories.length > 0) {
 this.getRelCategories.forEach((category) => {
   category.sections.forEach((section) => {
     const categoryName = category.categoryname;
     if (!sectionsMap.has(categoryName)) {
       sectionsMap.set(categoryName, []);
     }
     sectionsMap.get(categoryName).push({
       title: section.title,
       orderid: section.orderid,
       id: section.id,
       categoryName: category.categoryname,
       categoryId: category.id,
     });
   });
 });
} else {
 sectionsMap.set(null, [{ categoryId: null, id: 1, title: 'Better..' }]);
}

// Flatten the map values into an array and sort by orderid
const sectionsArray = Array.from(sectionsMap.values()).flat();

return sectionsArray.sort((a, b) => a.orderid - b.orderid);
},
showNavigation() {
      // Check if the number of items to show equals the total items
      // If so, hide the navigation arrows
      return this.orderedTabs.length > this.numberOfItemsToShow;
    },
numberOfItemsToShow() {
      // Define the breakpoints for different device sizes
      const breakpoints = {
        xs: 1, // Extra small devices
        sm: 1, // Small devices
        md: 2, // Medium devices
        lg: 3, // Large devices
        xl: 4, // Extra large devices
      };

      // Determine the current device size
      const width = window.innerWidth;
      let size = 'lg'; // Default size for large devices

      if (width < 576) {
        size = 'xs';
      } else if (width < 768) {
        size = 'sm';
      } else if (width < 992) {
        size = 'md';
      } else if (width < 1200) {
        size = 'lg';
      } else {
        size = 'xl';
      }

      // Return the number of items to show based on the device size
      return breakpoints[size];
    },

 currentUser() {
   return this.$store.state.auth.user;
 }
},
mounted() {
 const route=useRoute();
 this.sectionId = route.params.sectionId
 this.category = route.params.categoryId
 this.sortable = Sortable.create(this.$refs.sortableList, {
   onEnd: this.onSortEnd.bind(this) 
 });
},

components: {
 VueFinalModal,
 ckEditor,
 Carousel,
    Slide,
    Pagination,
    Navigation,
},
 setup() {
   const toast = useToast();
   return { toast  }
 },
};
</script>
<style scoped>

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.custom-file {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px; 
  }

  .custom-file-input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .file-chooser-text {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .file-chooser-text:hover {
    background-color: #0069d9;
  }

  .image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 10px; 
  }
  .carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
  .modal-footer {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .modal-footer button {
    margin: 10px; /* Adjust the value to increase/decrease the space */
  }
  .preview-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>