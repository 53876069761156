<template>
<header-component :key="headerKey" :isLoggedIn="isLoggedIn"></header-component>
  <section id="hero" class="d-flex flex-column justify-content-center align-items-center">
    <div>
      <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
    <button class="modal__close" @click="showModal = false">

    </button>
    <span class="modal__title"><i class="fas fa-newspaper"></i></span>
    <div class="modal__content">
      <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text" id="inputGroup-sizing-default">Header</span>
    </div>
    <input type="text" class="form-control" v-model="header" aria-label="Default" aria-describedby="inputGroup-sizing-default">
  </div>
  <div class="input-group mb-3">
  <div class="input-group-prepend">
     <span class="input-group-text" id="inputGroup-sizing-default">Description</span>
    </div>
  <input type="text" class="form-control" enctype="multipart/form-data" v-model="description" aria-label="Default" aria-describedby="inputGroup-sizing-default">
  </div>
    </div>
    <div class="modal__action" >
      <div v-if="this.showUpdate == true" ><button type="button" class="btn btn-primary btn-sm"  @click="editsItemCommit">Update</button></div>
      &emsp;
      <button type="button" class="btn btn-primary btn-sm" @click="showModal = false">Cancel</button>
    </div>
  </vue-final-modal>
</div >
  <div v-for="item in setSiteData()" :key="parseInt(item.id)">
    <div class="container text-center text-md-left" >
      <h1 data-aos="zoom-in-down" data-aos-duration="5000"><div v-html="item.header"></div></h1>
      <h2 data-aos="zoom-in-down" data-aos-duration="5000"><div v-html="item.description"></div></h2>
</div>
    </div>


  </section>
  <div v-if="isAuthenticated">
        <a class="siteInfoButton" @click="editsItem()"><i class="fas fa-edit" /></a>
      </div>

  <div class="d-flex flex-column justify-content-center align-items-center">
    <h2 data-aos="zoom-in-down" data-aos-duration="5000" style="color: #4f5a62;margin-top: -10px;font-weight: bold">
      <div>{{ currentRouteName }}</div></h2>
    </div>
<router-view></router-view>
</template>

<script>
import headerComponent from './components/subcomponents/headerComponent.vue';
import gql from "graphql-tag";
import { VueFinalModal } from 'vue-final-modal'
//import ckEditor from './components/subcomponents/ckeditor'
import {useToast } from "vue-toastification";
import { isAuthenticated } from './services/auth.check';
import {apolloContext} from './apollo.provider'


const UPDATE_SITE = gql`
  mutation updateSite($id: Int, $header: String, $description: String)
  {
  updateSite(
    id: $id
    header: $header
    description: $description
 ) 
    {
    id
    }

  }`;


export default {
  data: function() {
    return {
      isAuthenticated: isAuthenticated(),
      isLoggedIn: false,
      header: "",
      description: "",
      headerKey: 0,
      showModal: false,
      showUpdate: true,
      id: "",
      getSite:[],
			editorData: ''
    };
  },
 components: {
  headerComponent,
    VueFinalModal,
   // ckEditor
  },
  props: {
  section: String,
  },
  apollo: {
    getSite: {
      query: gql`
      {
        getSite {
          id,
          description,  
          header
       }
      }
    `
    }
  },
  async created() {
    this.isLoggedIn = await isAuthenticated();
    this.authReady = true; // Set authReady to true once authentication status is fetched
  },
  setup() {
      const toast = useToast();
      return { toast  }
    },
  computed: {
    subcomponentData() {
      return this.$store.state.subcomponentData;
    }
  },
  watch: {
    subcomponentData() {
      this.headerKey += 1;
    }
  },
  methods: {
    

    setSiteData(){
      var setSiteData = []
      this.getSite.forEach(function(item) {
        setSiteData.push(item)
      });
      if(setSiteData.length > 0){

    this.description = setSiteData[0].description
    this.header = setSiteData[0].header
    this.id = setSiteData[0].id
  }
    return  setSiteData
  },
  
editsItem(){
this.showModal = true
},
editsItemCommit: function(){
      apolloContext.requiresAuth = true; // Set requiresAuth flag to true
      this.showModal = false
      this.$apollo.mutate({
        mutation: UPDATE_SITE,
        variables: {
          id: this.id,
          header: this.header,
          description: this.description
        }
      // eslint-disable-next-line no-unused-vars
    }).then(data => {
     
     this.loading = false
     this.showModal = false
     this.toast.success("Your site info was successfully updated");
     this.$apollo.queries.getSite.refetch()
     this.$apollo.queries.getSite.refetch()
   })
   .catch(error => {
   console.log('errors', error.err)
     this.loading = false
   this.toast.error("Something happened, no update was done!");
 });

  },
}
}


</script>

<style scoped>

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}

.modal-footer {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .modal-footer button {
    margin: 10px; /* Adjust the value to increase/decrease the space */
  }
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>


<style src="./assets/css/style.css"/>