<template>
      <categories></categories>
      <about-component></about-component>
      <news-component id="news"></news-component>
      <contact></contact>
</template>
<script>

import newsComponent from './components/subcomponents/news.vue';
import aboutComponent from './components/subcomponents/about.vue';
import contact from './components/subcomponents/contact.vue';
import categories from './components/Categories.vue'
import {useToast } from "vue-toastification";


export default {

 
  components: {
  newsComponent,
  aboutComponent,
  contact,
  categories,
  },
  props: {
  section: String,
  },
  setup() {
      const toast = useToast();
      return { toast  }
    },
};

</script>