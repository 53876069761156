import {createWebHistory, createRouter } from "vue-router"
import Home from '../Home.vue'
import CategoryPage from '../components/page.vue'
import STATUS_404 from '../404.vue'
import Register from '../components/Register.vue'
import Login from "../components/Login.vue";
//import AuthService from "../services/auth.service";


const Profile = () => import("../components/Profile.vue")


const routes = [
    {
        path: '/',
        name: "Home",
        component: Home
    },
    {
        path: '/register',
        name: "Register",
        component: Register
    },
      {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
    },
       {
    path: "/login",
    component: Login,
  },
  {
    path: '/pages/:id/',
    name: 'CategoryPage',
    component: CategoryPage
  },
  {
    path: '/pages/:categoryId/:sectionsId',
    name: 'SectionsPage',
    component: CategoryPage,
    props: true
  },
      
    {
        path: '/about',
        name: "About",
        component: Home
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: STATUS_404
    }
]


const router = createRouter(
    {
        history: createWebHistory(),
        routes,
        // eslint-disable-next-line no-unused-vars
        scrollBehavior(to, from, savedPosition) {
            if (to.hash) {
              return {
                el: to.hash,
              }
            }
          }
        
    }
);

//router.beforeEach((to, from, next) => {
  //const publicPages = ['/logins', '/register'];
  //const authRequired = publicPages.includes(to.path);
  //const loggedIn = localStorage.getItem('user');


  // trying to access a restricted page + not logged in
  // redirect to login page
  //if(loggedIn)
  //  {
  //    AuthService.validate()
  //  }
  //if (authRequired && !loggedIn) {
  //  next('/login');
  //} else {
  //  next();
  //}
//});
export default router