import {createApp} from 'vue'
import App from '@/App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import {library} from "@fortawesome/fontawesome-svg-core";
import {faPlus, faMinus, faTrash, faCheck, faPhone, faPlusCircle, faEdit, faNewspaper, faUserLock, faUserTie, faBars, faUniversalAccess} from "@fortawesome/free-solid-svg-icons"
import {fab} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {dom} from "@fortawesome/fontawesome-svg-core"
import * as apolloProvider from './apollo.provider'
import store from "./store";
import { vfmPlugin } from 'vue-final-modal'
import CKEditor from '@ckeditor/ckeditor5-vue'
import AOS from 'aos'
import 'aos/dist/aos.css'
import easySpinner from 'vue-easy-spinner';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSweetalert2 from 'vue-sweetalert2';
import Carousel from 'vue-carousel';
dom.watch();

library.add(fab, faPlusCircle, faPlus, faMinus, faTrash, faCheck, faPhone, faEdit, faNewspaper, faUserLock, faUserTie,faBars, faUniversalAccess)

const options = {
    isEnabled: false,
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};


createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router)
  .use(apolloProvider.provider)
  .use(store)
  .use(vfmPlugin)
  .use(CKEditor)
  .use(AOS.init())
  .use(Toast, options)
  .use(VueSweetalert2)
  .use(Carousel)
  .use(easySpinner, {
    /*options*/
    prefix: 'easy',
  })
  .mount('#app');

