<template>
  <section id="subsections" class="subsections">
   <div class="container">
    <vue-final-modal v-model="deleteModal" classes="modal-container" >
      <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Do you really want to delete {{ deleteTitle }}?</h5>
      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer">
        <button type="button" @click="deleteItem()" class="btn btn-primary">Yes</button>
        <button type="button" @click="deleteModal = false" class="btn btn-secondary" data-dismiss="modal">No</button>
      </div>
    </div>
    </vue-final-modal>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showModal = false">
        <mdi-close></mdi-close>
      </button>
      <span class="modal__title"><i class="fas fa-newspaper"></i></span>
      <div class="modal__content">
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text" id="inputGroup-sizing-default">Title</span>
    </div>
    <input type="text" class="form-control" v-model="title" aria-label="Default" aria-describedby="inputGroup-sizing-default">
  </div>
  <div class="input-group mb-3">
  <div class="input-group-prepend">
      <span class="input-group-text" id="inputGroup-sizing-default">Text</span>
    </div>
  <input type="text" class="form-control" enctype="multipart/form-data" v-model="editorData" aria-label="Default" aria-describedby="inputGroup-sizing-default">
  </div>
  <div class="input-group mb-3">
  <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" v-model="isPublished" id="flexSwitchCheckChecked" checked>
  <label class="form-check-label" for="flexSwitchCheckChecked" >{{ publicationStatus }}</label>
</div>
</div>
<div class="input-group mb-3">
  <div class="input-group-prepend">
    <label class="input-group-text" for="inputGroupFile">Image</label>
  </div>
  <div class="custom-file">
    <input type="file" class="custom-file-input" id="inputGroupFile" @change="handleImageUpload($event)" accept="image/*">
    <label class="custom-file-label" for="inputGroupFile">
      <span class="file-chooser-text">Choose image</span>
    </label>
  </div>
</div>


<div v-if="imagePreview" class="image-preview">
  <img :src="imagePreview" alt="Image Preview" class="preview-image">
</div>


</div>

     <div  class="modal__action">
        <div v-if="this.showSubmit == true" ><button type="button" class="btn btn-primary btn-sm" @click="submit">Confirm</button></div>
        <div v-if="this.showUpdate == true" ><button type="button" class="btn btn-primary btn-sm"  @click="editsItemCommit">Update</button></div>
        &emsp;
        <button type="button" class="btn btn-primary btn-sm" @click="showModal = false">Cancel</button>
      </div>
    </vue-final-modal>
  </div> 
      <div v-if="isAuthenticated" class="newsButton d-flex flex-row-reverse">
        <a class="newsButtonCreate" @click="createNewItem" ><i class="fas fa-plus-circle" /></a>
      </div>
      <vue-draggable v-model="publishedCategories"   item-key="orderid" group="people" 
  @start="drag=true" tag="div">
      <div class="container" data-aos="fade-up">
        
        <div class="row" >
         
          <div class="col-md-4 col-lg-2 d-flex align-items-center justify-content-center mb-5 mb-lg-0" v-for="(item, i) in publishedCategories()" :key="i">
  <div class="icon-box-container" style="position: relative;">
    <div v-if="isAuthenticated" class="newsButton" style="position: absolute; top: 0; left: 0; margin: -50px; margin-left: 10px;">
      <a class="newsButtonEdit" @click="editsItem(item.description, item.id, item.categoryname, item.published)">
        <i class="fas fa-edit"></i>
      </a>
      <a class="newsButtonEdit" @click="deletePop(item.id, item.categoryname)">
        <i class="fas fa-trash"></i>
      </a>
    </div>
    <div @click="categories(item.categoryname,item.id)" style="text-decoration: none;">
      <div class="icon-box" data-aos="fade-up" data-aos-delay="100" style="text-align: center; height: 470px; width: 90%; position: relative; margin-left: 10px;">
        <img :src="getBase64Image(item.base64Avatar)" alt="GLENLOCHY DESTILLERY" style="width: 100px; height: 100px; border-radius: 50%;">
        <h4 class="title"><a href=""><div v-html="item.categoryname"></div></a></h4>
        <h5 class="description"><div v-html="item.description"></div></h5>
      </div>
    </div>
  </div>
</div>
        </div>
      
        </div>
      </vue-draggable>
    </section>
</template>

<script>
import gql from 'graphql-tag';
import {useToast } from "vue-toastification";
import { VueFinalModal } from 'vue-final-modal'
import {apolloContext} from '../apollo.provider'
import { isAuthenticated } from '../services/auth.check';
//import { Draggable } from 'vue3-draggable';
//import ckEditor from './subcomponents/ckeditor'

const FETCH_CATEGORIES = gql`
{
  getCategories {
    id
    categoryname
    base64Avatar
    description
    created 
    published
  }
}`;
const UPDATE_CATEGORY_ITEM = gql`
  mutation updateCategories($id: Int, $categoryname: String, $base64Avatar: String, $description: String,  $published: String) {
    updateCategories(
      id: $id
      categoryname: $categoryname
      base64Avatar: $base64Avatar, 
      description: $description, 
      published: $published
    ) {
      id
    }
  }
`;
const CREATE_CATEGORY_ITEM = gql`
mutation createCategories($categoryname: String, $base64Avatar: String, $description: String,  $published: String ) {
  createCategories(categoryname: $categoryname, base64Avatar: $base64Avatar, description: $description, published: $published){id}
}`;

const DELETE_CATEGORY = gql`
  mutation ($id: Int!) {
    deleteCategories(id: $id)
  }
`;


const GET_PRESET_FOR_SECTION = gql`
query getPresetCategory {
  getPresetCategory {
    categoryname
    sections {
      id
      title
      orderid
    }
    
  }
    }
`;

export default {
    data() {
    return {
      isAuthenticated: isAuthenticated(),
      getCategories: [],
      getPresetCategory: [],
      isPublished: "true",
      showModal: false,
      deleteModal: false,
      deleteTitle:'',
      deleteIndex:'',
      editorData: '',
      editorIndex: '',
      showSubmit: true,
      showUpdate: false,
      isEditable: true,
      editSave: 'Edit',
      CKEditor: {
       name: 'ckeditor',
      },
      imageFile: null, 
      imagePreview: '',
      imageBase64: '',
    };
  },  
  apollo: {
    getCategories: {
      query: FETCH_CATEGORIES
    },
    getPresetCategory: {
      query: GET_PRESET_FOR_SECTION
    },


  },
  methods: {
    getBase64Image(img) {
      var image;
      if (!image)
      { 
        image = "../assets/img/destillery.jpg";
      }

        image = `data:image/png;base64,${img}`;
      return image
    },
  publishedCategories() {
  var publishedCategories = [];
  this.getCategories.forEach(function(item) {
    if (item.published == "true" && isAuthenticated() == false) {
      publishedCategories.push(item);
    }
    if(isAuthenticated())
    {
      publishedCategories.push(item);
    }


  });

  return publishedCategories;
},
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
      reader.onload = (e) => {
        this.imageFile = file;
        this.imagePreview = e.target.result;
        this.imageBase64 = e.target.result.split(",")[1]; // Extract base64 string
        };
        reader.readAsDataURL(file);
      }
    },
categories(category, categoryId) {
      console.log(this.getPresetCategory)
      this.getPresetCategory.forEach((cat) => {
     if(cat.categoryname == category)
     {
      console.log(cat.categoryname)
      console.log(cat.sections)
      cat.sections.forEach((sec) => {
        console.log("sec: " + sec.title);
        console.log("sec: " + sec.id);
        this.$router.push({
          name: 'SectionsPage',
          params: { categoryId: categoryId, sectionsId: sec.id }
        });
      });
     }
});
},
    createNewItem()
      {
        this.showModal = true
        this.showSubmit = true
        this.showUpdate = false
        this.editorData = ""
        this.isPublished = "true"
        this.title = ""

      },
      editsItem(event, index, title, published){
      this.showSubmit = false
      this.isPublished = published
      this.showUpdate = true
      this.showModal = true
      this.editorIndex = index
      this.editorData = event
      this.title = title
      },
      deletePop(index, title){
      apolloContext.requiresAuth = true; 
      console.log("Ready to delete " + title)
      this.deleteTitle = title
      this.deleteModal = true
      this.deleteIndex = index
      },
      deleteItem(){
      apolloContext.requiresAuth = true; 
      console.log("Deleted: " + this.deleteTitle + " by user")
      this.deleteModal = false

      
 /** */      this.$apollo.mutate({
        mutation: DELETE_CATEGORY,
        variables: {
          id: this.deleteIndex //index,
        }
      });
      this.$apollo.queries.getCategories.refetch()
      
      },
      editsItemCommit(){
      
       apolloContext.requiresAuth = true; 
      if(this.title != "")
      {
      this.$apollo.mutate({
        mutation: UPDATE_CATEGORY_ITEM,
        variables: {
          id: this.editorIndex,
          description: this.editorData,
          categoryname: this.title,
          base64Avatar: this.imageBase64,
          published: this.isPublished
        }
    // eslint-disable-next-line no-unused-vars
    }).then(data => {
     
      this.loading = false
      this.showModal = false
      this.showUpdate = false
      this.showSubmit =  true
      this.editorData = 
      this.toast.success("Your post was successfully updated");
    })
    .catch(error => {
    console.log('errors', error.err)
      this.loading = false
    this.toast.error("Something happened, any large images?");
  });
        this.$apollo.queries.getCategories.refetch()
        }else{
        this.toast.error("You need a title");
      }
  },
      async submit(e) {
      apolloContext.requiresAuth = true; 
      if(this.title != "")
      {
      const description = this.editorData;
      const categoryname = this.title;

      e.preventDefault();
       await this.$apollo.mutate({
        mutation: CREATE_CATEGORY_ITEM,
        variables: {
        categoryname,
        base64Avatar: this.imageBase64,
        description,      
        published: this.isPublished
        }
    }).then(
      this.loading = false,
      this.showModal = false,
      this.showUpdate = false,
      this.showSubmit =  true,
      this.editorData = '',
      this.toast.success("Your post was successfully updated")
    )
    .catch(error => {
    console.log('errors', error.error)
    this.loading = false
    this.toast.error("Something happened");
  });
       this.$apollo.queries.getCategories.refetch()
        }else{
        this.toast.error("You need a title");
      }
      }     
  },

  computed: {
    publicationStatus() {
      return this.isPublished ? "published" : "not published";
    },
  },
  components: {
  VueFinalModal,

//  ckEditor
  },
  props: {
  section: String,
  },
  setup() {
      const toast = useToast();
      return { toast  }
    },
};

</script>

<style scoped>

::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.custom-file {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px; 
  }

  .custom-file-input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .file-chooser-text {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .file-chooser-text:hover {
    background-color: #0069d9;
  }

  .image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1);
    margin-top: 10px; 
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .modal-footer button {
    margin: 10px; /* Adjust the value to increase/decrease the space */
  }
  .preview-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
