import { reactive } from 'vue';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { createApolloProvider } from "@vue/apollo-option";

const httpLink = createHttpLink({
  uri: "/api/graphql/"
});

const apolloContext = reactive({
  requiresAuth: false, // Default value of requiresAuth
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  // Check if requiresAuth flag is set to true
  console.log(apolloContext.requiresAuth + " token: " + token)
  if (apolloContext.requiresAuth && token) {
    // Return the headers to the context so HTTP link can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
  console.log(headers)
  return { headers };
});

console.log( authLink.concat(httpLink))

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const provider = createApolloProvider({
  defaultClient: apolloClient,
});

export { apolloContext }; // Export the reactive context object
