<template>
 <ckeditor :editor="CKEditor.editor"  :config="CKEditor.editorConfig" />
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FindAndReplace from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle.js';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript.js';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

export default {
  name: 'editor',
      data() {
    return {
			CKEditor: {
				editor: ClassicEditor,
				editorConfig: {
          plugins: [
                      Base64UploadAdapter,
                      MediaEmbed,
                      Alignment,
                      Autoformat,
                      BlockQuote,
                      Bold,
                      Essentials,
                      FindAndReplace,
                      FontBackgroundColor,
                      FontColor,
                      FontFamily,
                      FontSize,
                      Heading,
                      Highlight,
                      HtmlEmbed,
                      Image,
                      ImageInsert,
                      ImageResize,
                      ImageStyle,
                      ImageToolbar,
                      ImageUpload,
                      Indent,
                      Italic,
                      Link,
                      List,
                      ListStyle,
                      MediaEmbed,
                      Paragraph,
                      Strikethrough,
                      Subscript,
                      Superscript,
                      Table,
                      TableToolbar,
                      TextTransformation,
                      Underline],
          fontFamily: {
            options: ['default']
          },
          fontSize: {
            options: [
                9,
                11,
                13,
                17,
                19,
                21,
                23,
                25,
                27,
                30
            ]
        },
          toolbar: {
          items: [ '|',
                  'uploadImage',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'outdent',
                  'indent',
                  '|',
                  'imageUpload',
                  'blockQuote',
                  'insertTable',
                  'mediaEmbed',
                  'undo',
                  'redo',
                  'highlight',
                  'imageInsert',
                  'strikethrough',
                  'underline',
                  'fontSize',
                  'findAndReplace',
                  'fontBackgroundColor',
                  'fontColor',
                  'fontFamily',
                  'alignment']
          },
image: {
		toolbar: [
			'imageTextAlternative',
			'imageStyle:inline',
			'imageStyle:block',
			'imageStyle:side'
		]
	},
	table: {
		contentToolbar: [
			'tableColumn',
			'tableRow',
			'mergeTableCells'
		]
	},
     mediaEmbed: {
            previewsInData: true
        }
        },
       
      },
    };
  }, 
  }
</script>
