<template>
     <section id="news" class="news section-bg" >

      <div class="container">

        <div class="section-title">
          <h2 style="color: #4f5a62;">NEWS</h2>
        </div>
    <div>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showModal = false">

      </button>
      <span class="modal__title"><i class="fas fa-newspaper"></i></span>
      <div class="modal__content">
          <ck-editor v-model="CKEditor.editorData" ></ck-editor>
      </div>
      <div class="modal__action" >
        <div v-if="this.showSubmit == true" ><button type="button" class="btn btn-primary btn-sm" @click="submit">Confirm</button></div>
        <div v-if="this.showUpdate == true" ><button type="button" class="btn btn-primary btn-sm"  @click="editsItemCommit">Update</button></div>
        &emsp;
        <button type="button" class="btn btn-primary btn-sm" @click="showModal = false">Cancel</button>
      </div>
    </vue-final-modal>
      <div class="newsButton d-flex flex-row-reverse">
        <div v-if="isAuthenticated">
        <a class="newsButtonCreate" @click="showModal = true" ><i class="fas fa-plus-circle" /></a>
        </div>
      </div>
  </div>
<div class="reverseorder">
<div v-for="item in getAllNews" :key="parseInt(item.id)">
<div  class="card">
        <div class="newsButton d-flex flex-row-reverse">
          <div v-if="isAuthenticated">
      <a class="newsButtonEdit" @click="editsItem(item.data, item.id)"><i class="fas fa-edit" /></a>
        <a class="newsButtonEdit" @click="deleteItem(item.id)"><i class="fas fa-trash" /></a> 
    </div>
      </div>
  <div class="card-body" >
  <h5 class="card-title" ><div v-html="item.data"></div></h5>
    <p class="card-text"></p>
    
  </div>
</div>
<br>
</div></div>
		</div>
    </section>
</template>


<script>
import gql from "graphql-tag";
import { VueFinalModal } from 'vue-final-modal'
import ckEditor from './ckeditor'
import { isAuthenticated } from '../../services/auth.check';
import {apolloContext} from '../../apollo.provider'

  const UPDATE_NEWS = gql`
  mutation updateNews($id: Int, $data: String)
  {
  updateNews(
    id: $id
    data: $data
 ) 
    {
    id
    }

  }`;
const CREATE_NEWS = gql`
  mutation createNews($id: Int, $data: String, $userid: Int)
  {
  createNews(
    id: $id
    data: $data
    userid: $userid) 
    {
    id
    }
  }`;

const DELETE_NEWS = gql`
  mutation DeleteNews($id: Int!) {
    deleteNews(id: $id)
  }

`;



export default {
  name: 'News',
      data() {
    return {
      isAuthenticated: isAuthenticated(),
      showModal: false,
      showSubmit: true,
      showUpdate: false,
			CKEditor: {
				editorData: ''
			},
      isEditable: true,
      editSave: 'Edit',
      getAllNews: [],
    };
  },  
    apollo: {
    getAllNews: {
      query: gql`
      {
        getAllNews {
        id
        data
        userid
       }
      }
    `
    }
  },

  methods: {

      editsItem(event, index){
      this.showSubmit = false
      this.showUpdate = true
      this.showModal = true
      this.CKEditor.editorIndex = index
      this.CKEditor.editorData = event

  },
    deleteItem(index) {
      apolloContext.requiresAuth = true; // Set requiresAuth flag to true

      this.$apollo
        .mutate({
          mutation: DELETE_NEWS,
          variables: {
            id: index,
          },
        })
        .then(() => {
          // Handle success
          //this.$router.go();
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    },

    editsItemCommit: function(){
      apolloContext.requiresAuth = true; 
      this.showModal = false
      this.showUpdate = false
      this.showSubmit =  true
      this.CKEditor.editorData = 
      this.$apollo.mutate({
        mutation: UPDATE_NEWS,
        variables: {
          id: this.CKEditor.editorIndex,
          data: this.CKEditor.editorData
        },
        context: {
          requiresAuth: true,
        },
    });
   //this.$router.go()
  },
      submit(e) {
      apolloContext.requiresAuth = true; 
      this.showModal = false;
      const data = this.CKEditor.editorData;
       e.preventDefault();
       this.$apollo.mutate({
        mutation: CREATE_NEWS,
        variables: {
        id: 4,
        data: data,
        userid: 2
        }
    });
      }

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  components: {
    VueFinalModal,
    ckEditor
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
