<template>
     <section id="about" class="news section-bg" >

      <div class="container">

        <div class="section-title">
          <h2 style="color: #4f5a62;">ABOUT</h2>        
        </div>
    <div>
    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">
      <button class="modal__close" @click="showModal = false">
        <mdi-close></mdi-close>
      </button>
      <span class="modal__title"><i class="fas fa-universal-access"></i></span>
      <div class="modal__content">

            <ck-editor v-model="CKEditor.editorData" ></ck-editor>
      </div>
      <div class="modal__action" >
        <div v-if="this.showSubmit == true" ><button type="button" class="btn btn-primary btn-sm" @click="submit">Confirm</button></div>
        <div v-if="this.showUpdate == true" ><button type="button" class="btn btn-primary btn-sm"  @click="editsItemCommit">Update</button></div>
        &emsp;
        <button type="button" class="btn btn-primary btn-sm" @click="showModal = false">Cancel</button>
      </div>
    </vue-final-modal>
      <div class="newsButton d-flex flex-row-reverse">
        <div v-if="currentUser">
        <a class="newsButtonCreate" @click="showModal = true" ><i class="fas fa-plus-circle" /></a>
        </div>
      </div>
  </div>
<div class="reverseorder">
<div v-for="about in getAbout" v-bind:key="about.id" >
<div  class="card">
        <div class="newsButton d-flex flex-row-reverse">
          <div v-if="isAuthenticated">
        <a class="newsButtonEdit" @click="editsItem(about.data, about.id)"><i class="fas fa-edit" /></a>
        </div>
      </div>
  <div id="editor"  class="card-body" >
   
  <h5 class="card-title" ><div v-html="about.data"></div></h5>
    <p class="card-text"></p>
    
  </div>
</div>
<br>
</div></div>
		</div>
    </section>
</template>


<script>
import gql from "graphql-tag";
import { VueFinalModal } from 'vue-final-modal'
import ckEditor from './ckeditor'
import {apolloContext} from '../../apollo.provider'
import { isAuthenticated } from '../../services/auth.check';

   const ALL_ABOUT = gql`
   {
    getAbout {
    id
    data
    }
  }
    `;
  const UPDATE_ABOUT = gql`
  mutation updateAbout($id: Int, $data: String)
  {
  updateAbout(
    id: $id
    data: $data
 ) 
    {
    id
    }
  }`;
const CREATE_ABOUT = gql`
  mutation createAbout($data: String)
  {
  createAbout(
    data: $data
  ) 
    {
    id
    }
  }`;

const DELETE_ABOUT = gql`
  mutation ($id: Int!) {
    deleteAbout(id: $id)
  }
`;

export default {
  name: 'About',
      data() {
    return {
      isAuthenticated: isAuthenticated(),
      showModal: false,
      showSubmit: true,
      showUpdate: false,
			CKEditor: {
				editorData: '',
    
			},
      isEditable: true,
      editSave: 'Edit',
      getAbout: []
    };
  },  
    apollo: {
    getAbout: {
      query: ALL_ABOUT
    }
  },

  methods: {

      editsItem: function(event, index){
      apolloContext.requiresAuth = true; 
      this.showSubmit = false
      this.showUpdate = true
      this.showModal = true
      this.CKEditor.editorIndex = index
      this.CKEditor.editorData = event

      this.$apollo.queries.getAbout.refetch()

  },
      deleteItem: function(index){
      apolloContext.requiresAuth = true; 
       this.$apollo.mutate({
        mutation: DELETE_ABOUT,
        variables: {
          id: index,
        }
    });

    this.$router.go()
    this.$apollo.queries.getAbout.refetch()
  },

    editsItemCommit: function(){
      apolloContext.requiresAuth = true; 
      console.log(this.CKEditor.editorIndex)
      console.log(this.CKEditor.editorData )
      
      this.showModal = false
      this.showUpdate = false
      this.showSubmit =  true
      this.CKEditor.editorData = 
      this.$apollo.mutate({
        mutation: UPDATE_ABOUT,
        variables: {
          id: this.CKEditor.editorIndex,
          data: this.CKEditor.editorData
        }
    });

  },
      submit(e) {
      apolloContext.requiresAuth = true; 
      this.showModal = false;
      const data = this.CKEditor.editorData;
      console.log("Data: "  + data)
      e.preventDefault();
       this.$apollo.mutate({
        mutation: CREATE_ABOUT,
        variables: {
        data
        }
    });
    this.$apollo.queries.getAbout.refetch()
      }
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  components: {
    VueFinalModal,
    ckEditor
  }
};
</script>
<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90%;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  flex-grow: 1;
  overflow-y: auto;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>
