export function isAuthenticated() {
  const token = localStorage.getItem('token')

  if (token) {
    // Decode the token to get the expiration time
    const decodedToken = decodeToken(token)

    if (!decodedToken || !decodedToken.exp) {
      // Invalid token format, remove it from localStorage
      localStorage.removeItem('token')
      return false
    }

    const expirationTime = decodedToken.exp * 1000 // Convert expiration time to milliseconds

    // Check if the token has expired
    if (Date.now() >= expirationTime) {
      // Token has expired, remove it from localStorage
      localStorage.removeItem('token')
      return false 
    }

    return true
  }

  return false
}

export function getTokenInfo(){
  const token = localStorage.getItem('token')

  const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    console.log("jsonPayload: " + jsonPayload)
    return JSON.parse(jsonPayload)

}
function decodeToken(token) {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
  } catch (error) {
    return null
  }
}