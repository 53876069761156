import axios from 'axios';

const API_URL = 'https://www.glenlochy.com/api/';


class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'auth', {
        username: user.username,
        password: user.password
      })
      .then(this.handleResponse)
      .then(response => {
        console.log(response)
        if (response.data.jwtToken) {
          localStorage.setItem('token', response.data.jwtToken);
        }

        return response;
      });
  }

  logout() {
    localStorage.removeItem('token');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }

  handleResponse(response) {
    if (response.status === 401) {
      this.logout();
      location.reload(true);

      const error = response.data && response.data.message;
      return Promise.reject(error);
    }

    return Promise.resolve(response);
  }
}

export default new AuthService();
