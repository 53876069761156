<template>
<div id="main-app" >
  <div style="text-align: center; padding: 20px;">
          <h1>Oops! 404 - Page Not Found</h1>
          <h4>Looks like you've had one too many and stumbled into the wrong place...</h4>
        </div>
<font-awesome-icon icon="whiskey-glass" />
</div>
<form action="/" @keydown="debug">
</form>
</template>

<script>
export default {
    methods: {
  debug () {
  }
} 
}


</script>