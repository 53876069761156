<template>
        <section id="contact" class="contact section-bg">
      <div class="container">

        <div class="section-title">
          <h2 style="color: #4f5a62;">Contact</h2>
          <p>Write me a message!</p>
        </div>

        <div class="row mt-5 justify-content-center" @load="spinner()"  >
          <div class="col-lg-10">
            <form class="form"  @submit.prevent="submit" ref="formContainer"> 
              <div class="row">
                <div class="col-md-6 form-group">
                  
                   <input type="text" v-model="username" placeholder="Your Name" class="form-control" id="name" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" v-model="email" name="email" id="email" placeholder="Your Email" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control"  v-model="subject" name="subject" id="subject" placeholder="Subject" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" v-model="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit" :disabled="this.buttonBool"  >Send Message&nbsp;&nbsp;   <div class="loader" v-if="loading"><easy-spinner /></div></button></div>
            </form>
          </div>

        </div>

      </div>
    </section>
</template>

<script>
/* eslint-disable no-unused-vars */
import { gql } from 'graphql-tag';
import { execute } from 'graphql';
import { InMemoryCache } from "apollo-cache-inmemory";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
 import { useToast } from "vue-toastification";


const CREATE_MAIL = gql`
   mutation createMail($message: String, $subject: String, $email: String )
   {
   createMail(
     message: $message
     subject: $subject
     email: $email) 
     {
     id
     }
   }`;


export default {
  data() {
    return {
      message: "",
      subject: "",
      email: "",
      fullPage: false,
      loading: false,
      buttonBool: false
    };
  },
apollo: {},
methods: {

    submit(e) {   
      this.buttonBool == true
      this.loading = true
      var message = this.message
      var subject = this.subject
      var email = this.email
       this.$apollo.mutate({
        mutation: CREATE_MAIL,
        variables: {
        message,
        subject,
        email
        }
    }).then(data => {
      this.loading = false
      this.toast.success("Your email was successfully sent!");
    })
    .catch(error => {
    console.log('errors', error.graphQLErrors)
    this.loading = false
    this.toast.error("Something happened, please try again");
  });
  
  }  
},
computed: {

},
setup() {
      const toast = useToast();
      return { toast }
    },
};
</script>