import { createStore } from "vuex";
import { auth } from "./auth.module";


const store = createStore({
  modules: {
    auth,
  },
  state: {
    isLoggedIn: false
  },
    mutations: {
    setLoginStatus(state, status) {
      state.isLoggedIn = status;
    }
  }
});

export default store;
